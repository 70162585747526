<template>
  <div class="main has-header has-footer">
    <div class="main_box">
      <img src="@assets/images/icon-hot-tip.png" alt="icon" class="icon-hot-tip" />
      <p class="hint_text">
        为了确保您的个体户合规注册
        <br />请按照相关提示录制意愿视频
      </p>
      <p class="footer_btn" @click="triggerToShoot">开始录制</p>
    </div>
  </div>
</template>

<script>
export default {
  components: {},
  data() {
    //这里存放数据
    return {};
  },
  //监听属性 类似于data概念
  computed: {},
  //监控data中的数据变化
  watch: {},
  //方法集合
  methods: {
    triggerToShoot() {
      console.log(123);
      this.$router.push("/UploadVideo/shoot");
    }
  },
  mounted() {}
};
</script>

<style lang='less' scoped>
.main_box {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}
.icon-hot-tip {
  width: 200px;
  height: 150px;
  margin-top: 115px;
}
.hint_text {
  padding: 0;
  margin-top: 20px;
  font-size: 17px;
  font-weight: 600;
  color: #333333;
}
.last_text {
  margin: 0;
  padding-top: 10px;
}
.footer_btn {
  width: 335px;
  height: 40px;
  margin-top: 60%;
  background: #c92e26;
  text-align: center;
  line-height: 40px;
  font-size: 15px;
  font-weight: 500;
  color: #ffffff;
}
</style>